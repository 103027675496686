<template>
  <app-article-view ref="articleView">
    <div slot="actions" class="col-lg-12">
      <app-article-create-modal
        titleMessage="topicOffer.transfer_article"
        v-if="showModal"
        :article-to-take-over="article"
        @close="toggleTransferModal"
      >
      </app-article-create-modal>
      <ModuleFormButton
        icon="close"
        class="m-l-10 float-right"
        @click="$router.push('/topicOffer')"
      />
      <button
        v-if="isTransferButtonVisible"
        type="button"
        class="btn btn-green float-right"
        @click="toggleTransferModal"
      >
        {{ $t('topicOffer.transfer') }}
      </button>
    </div>
    <template slot="history">
      <h3 class="article-edit-header">{{ $t("topicOffer.transferred_articles") }}</h3>
      <router-link
        v-for="article in transferredArticles" :key="article.id"
        tag="a"
        :to="{name: 'articleByDocumentId', params: {id: article.documentId}}"
        target="_blank"
      >
        {{ article.documentId }}
      </router-link>
    </template>
  </app-article-view>
</template>

<script>
import ArticleCreateModal from '../article/ArticleCreateModal'
import ArticleView from '../../views/article/ArticleView'
import PermissionService from '@/services/PermissionService'
import ModuleFormButton from '@/components/shared/ModuleFormButton'

export default {
  name: 'TopicOffer',
  components: {
    ModuleFormButton,
    appArticleCreateModal: ArticleCreateModal,
    appArticleView: ArticleView
  },
  data () {
    return {
      showModal: false,
      transferredArticles: []
    }
  },
  computed: {
    article () {
      return this.$refs.articleView.article
    },
    isTransferButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.ARTICLE_OFFER_TOPIC_OFFER_PERMISSIONS.transferArticleButton
      return this.$store.getters['user/hasPermission'](permission)
    }
  },
  methods: {
    toggleTransferModal () {
      this.showModal = !this.showModal
    }
  },
  watch: {
    article (article) {
      this.$store.dispatch('article/fetchTransferredArticles', article.id).then((articles) => {
        this.transferredArticles = articles
      })
    }
  }
}
</script>
